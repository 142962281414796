import React, { useContext, useState } from 'react'
import styles from './styles/HeroPricing.module.css';
import { AiOutlineCheck } from 'react-icons/ai';
import Button from './ui/Button';
import { AppContext } from '../AppContext';
import { Link } from 'react-router-dom';
import ToggleButton from './ui/ToggleButton';

const CARDS = [
    /*{
        title: "Pay by Bank App",
        feeComponent: <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className={styles.fee}>0.4% <span className={styles.lower}>per transaction</span></span>
            <span className={styles.fee}>&nbsp;</span>
        </div>,
        features: ["No Contract", "Instant Payout", "No subscription fee", "One flat rate"]
    },*/
    {
        title: "In-person Payments",
        feeComponent: <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className={styles.fee}>0.5% <span className={styles.lower}> on Pay By Bank App</span></span>
            <span className={styles.fee}>1.0% <span className={styles.lower}> on All Cards (1) </span></span>
        </div>,
        features: ["No Contract", "Instant Payout", "No subscription fee", "One flat rate"],
        dipNote: `(1) extra 0.6% + 25p on Tap To Pay`
    },
    {
        title: "Payment Links",
        feeComponent: <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className={styles.fee}>0.5% <span className={styles.lower}> on Pay By Bank App</span></span>
            <span className={styles.fee}>1.5% + 20p <span className={styles.lower}> Standard Cards (2) </span></span>
        </div>,
        features: ["No Contract", "Weekly Payouts", "No subscription fee", "Send anywhere"],
        dipNote: <>&nbsp; <Link to="/faq/pricing-card">(2) See all card prices</Link></>
    },
    {
        title: "Order Management",
        feeComponent: <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className={styles.fee}>1.5% + 15p</span>
            <span className={styles.fee}>&nbsp; </span>
        </div>,
        features: ["No Contract", "Card and Pay by Bank", "No subscription fee", "Remote Ordering"],
        dipNote: <>&nbsp;</>
    }
]

const HeroPricing = () => {

    const [cardIdx, setCardIdx] = useState(0);

    return (
        <div className={`${styles['main-content-wrapper']}`}>
            <div className={`main-content ${styles.main} `}>
                <span className={styles.tag}>
                    Join The Revolution
                    <div className={styles['circle-1']}></div>
                    <div className={styles['circle-2']}></div>
                </span>
                <h1>Ultra-Low <br />Simple <span className={styles['highlight']}>Pricing</span></h1>

                <ToggleButton labelArr={["In Person", "Payment Links", "Order Mgmt."]} activeLabelIdx={cardIdx}
                    onChange={(selectedIdx) => setCardIdx(selectedIdx)}
                    wrapperStyles={{ maxWidth: '400px', width: '100%', margin: '0px auto 30px auto' }} />

                <div className={styles['card-list']}>
                    {[CARDS[cardIdx]].map((card, idx) => {
                        return (
                            <div key={idx} className={styles.card}>
                                <span className={styles.title}>{card.title}</span>
                                {card.feeComponent}
                                <div className={styles['feature-list']}>
                                    {card.features.map((item, idx) => {
                                        return (
                                            <div key={idx} className={styles['feature-item']}>
                                                <div className={styles.bullet}><AiOutlineCheck /></div>
                                                <span>{item}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                                <Button wrapperClass={styles['app-links']} text="Join Now" onClick={() => { window.location.href = "https://merchant.merqury.co.uk/auth" }} />
                                <span className={styles['dip-note']}>{card.dipNote}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default HeroPricing